import { reactive, toRefs, ref, nextTick } from 'vue'
import { ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import { getWxJsSDKConfigInfo } from '@/api/common'
import { getPointProductDetail } from '@/api/product'
import { useRoute } from 'vue-router'
import { detail } from '../types'
import videojs from 'video.js'
export function UseProductCredit() {
  const route = useRoute()
  const data = reactive({
    getVideoBase64: require('@/utils/videoCover.js'),
    videoId: (el: any) => {
      if (
        el &&
        data.videoRef.length < data.productDetail.swiperList.videoList.length
      ) {
        data.videoRef.push(el)
        console.log(data.videoRef)
      }
    },
    videoIdList: [] as any,
    videoPhototList: [] as any,
    swiper: {} as any,
    videoRef: [] as any,
    loading: true,
    type: route.query.type,
    current: 0,
    backIcon: require('../asset/grey-back.png'),
    shareIcon: require('../asset/grey-share.png'),
    nextIcon: require('../asset/nextIcon.png'),
    isWeiXin: false,
    wxPopup: false,
    sendData: JSON.stringify({
      type: 'creditProduct',
      productId: route.query.productId
    }),
    productDetail: {} as detail
  })
  const getDetail = () => {
    getPointProductDetail({
      userId: route.query.userId,
      type: route.query.type,
      companyId: route.query.companyId
    }).then((res: any) => {
      data.productDetail = res.data
      data.loading = false
      data.videoRef.forEach((item: any) => {
        item.load()
      })
      data.productDetail.swiperList.videoList.forEach((item, index) => {
        data.videoIdList.push('videoId' + index)
        console.log(data.getVideoBase64.getVideoBase64)

        data.getVideoBase64.getVideoBase64(item).then((res: any) => {
          data.videoPhototList[index] = res
        })
      })
    })
  }
  const prev = () => {
    data.swiper.prev()
  }
  const next = () => {
    data.swiper.next()
  }
  const onChange = (index: number) => {
    // data.videoRef.forEach((element: any, index2: number) => {
    //   if (index == index2) {
    //     console.log(index, index2)
    //     element.play()
    //   } else {
    //     element.pause()
    //   }
    // })
    data.videoIdList.forEach((item: any, index2: number) => {
      if (index == index2) {
        console.log(index, index2)
        videojs(item).play()
      } else {
        videojs(item).pause()
      }
    })
    data.current = index
  }
  const preview = (i: number) => {
    ImagePreview({
      images: data.productDetail.swiperList.imgList,
      startPosition: i
    })
  }
  const preview2 = (i: number) => {
    ImagePreview({ images: data.productDetail.detailInfo, startPosition: i })
  }
  const getWxJsSDKConfig = () => {
    getWxJsSDKConfigInfo({
      url: encodeURI(location.href.split('#')[0])
    }).then((res: any) => {
      const wxSet = res.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: wxSet.appId, // 必填，公众号的唯一标识
        timestamp: wxSet.timestamp, // 必填，生成签名的时间戳
        nonceStr: wxSet.nonceStr, // 必填，生成签名的随机串
        signature: wxSet.signature, // 必填，签名
        jsApiList: [
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'checkJsApi'
        ], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      })
      data.wxPopup = true
    })
    wx.ready(function () {
      data.videoIdList.forEach((item: any, index: number) => {
        if (index == 0) {
          videojs(item).play()
        } else {
          videojs(item).pause()
        }
      })
      console.log('ready')
    })
    wx.error((res: any) => {
      alert(JSON.stringify(res))
    })
  }

  const appSource = () => {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isiOS) {
      return 'ios'
    } else {
      return 'andriod'
    }
  }
  const isWenXin = () => {
    const ua = window.navigator.userAgent.toLowerCase()
    // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
    if (ua.indexOf('micromessenger') > -1) {
      data.isWeiXin = true
    } else {
      data.isWeiXin = false
    }
  }
  const openApp = () => {
    if (appSource() === 'andriod') {
      //  安卓处理
      const r = confirm('未安装APP? 是否下载')
      if (r) {
        ;(window as any).location = 'http://api.equnshang.com/web/guide.html'
      }
    } else {
      //  IOS处理
      //  跳转app store
      const r = confirm('未安装APP? 是否去App store查看')
      if (r) {
        ;(window as any).location = 'https://apps.apple.com/cn/app/id1553858645'
      }
    }
  }
  const handleErrorFn = () => {
    openApp()
  }
  const handleLaunchFn = (e: any) => {
    console.log(e)
  }
  const back = () => {
    const message = {
      companyId: route.query.companyId
    }
    ;(window as any).webkit.messageHandlers.back.postMessage(message)
  }
  const share = () => {
    const message = {
      type: route.query.type,
      companyId: route.query.companyId,
      userId: route.query.userId
    }
    ;(window as any).webkit.messageHandlers.shareProduct.postMessage(message)
  }

  return {
    ...toRefs(data),
    onChange,
    preview,
    preview2,
    getWxJsSDKConfig,
    handleErrorFn,
    handleLaunchFn,
    back,
    share,
    isWenXin,
    getDetail,
    prev,
    next
  }
}
