import https from '../service'
interface pData {
  type: any
  companyId?: any
  userId: any
}

const getPointProductDetail = (params: pData) => {
  return https.post({
    url: '/qsApi/MallController/getPointProductDetail',
    showLoading: true,
    params
  })
}

export { getPointProductDetail }
