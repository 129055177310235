
import { reactive, toRefs, onBeforeMount, onMounted, ref } from 'vue'
import '@videojs/themes/dist/forest/index.css'
import {
  Image as VanImage,
  Swipe,
  SwipeItem,
  ImagePreview,
  Skeleton
} from 'vant'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
// import 'video.js/dist/video-js.css'
// import '@videojs/themes/dist/forest/index.css'
import { Navigation } from 'swiper'
import { UseProductCredit } from './hooks/UseProductCredit'
import 'amfe-flexible'

export default {
  name: '',
  components: {
    [VanImage.name]: VanImage,
    Swipe,
    SwipeItem,
    Skeleton,
    VideoPlayer
  },
  setup() {
    const {
      swiper,
      videoId,
      type,
      getDetail,
      productDetail,
      onChange,
      preview,
      current,
      preview2,
      getWxJsSDKConfig,
      backIcon,
      shareIcon,
      isWeiXin,
      wxPopup,
      sendData,
      isWenXin,
      handleErrorFn,
      handleLaunchFn,
      back,
      share,
      loading,
      prev,
      next,
      nextIcon,
      videoPhototList
    } = UseProductCredit()
    getWxJsSDKConfig()
    isWenXin()
    getDetail()
    // onMounted(() => {})
    return {
      swiper,
      productDetail,
      onChange,
      preview,
      current,
      preview2,
      backIcon,
      shareIcon,
      isWeiXin,
      wxPopup,
      sendData,
      isWenXin,
      handleErrorFn,
      handleLaunchFn,
      back,
      share,
      type,
      loading,
      videoId,
      prev,
      next,
      nextIcon,
      videoPhototList
    }
  }
}
